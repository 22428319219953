import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"


const Tag = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout>
      <Helmet title={`Jessa Sparks | ${tag}`} />
      <h2>{tag}</h2>
        {posts.map(({ node }) => {
          return (
            <p key={node.id}>
              <Link to={node.frontmatter.path}>{node.frontmatter.title}</Link>
            </p>
          )
        })}
    </Layout>
  )
}

Tag.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tag

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`